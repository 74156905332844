<template>
  <div class="inline-block">
    <font-awesome-icon
      icon="dove"
      class="icon color-shift-morning"
      v-if="i == 0"
    ></font-awesome-icon>
    <font-awesome-icon
      icon="sun"
      class="icon color-shift-day"
      v-if="i == 1"
    ></font-awesome-icon>
    <font-awesome-icon
      icon="cloud"
      class="icon color-shift-middle"
      v-if="i == 2"
    />
    <font-awesome-icon
      icon="cloud-moon"
      class="icon color-shift-sunset"
      v-if="i == 3"
    ></font-awesome-icon>
    <font-awesome-icon
      icon="moon"
      class="icon color-shift-night"
      v-if="i == 4"
    ></font-awesome-icon>
    <div v-if="i == -1">n/a</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ServiceSituationIcon",
  data() {
    return {
      i: -1
    };
  },
  props: {
    data: null,
    icon: null
  },
  methods: {},
  mounted() {
    if(this.data != null) {
      this.i = this.getServiceSituationIcon(this.data)
    } else if(this.icon != null) {
      this.i = this.icon
    }
  },
  computed: {
    ...mapGetters([
      "getServiceSituationIcon",
    ]),
  },
};
</script>