<template>
    <div class="content-card w-full shadow-lg">
      <div class="card-header card-header-flex flex justify-between items-center px-2 rounded-t-lg bg-brand h-12">
        <p><b>{{ getWeekdayForDate(exchange.shift.header_date, false) }}, {{ getFormatedDate(exchange.shift.header_date) }}</b></p>
        <ServiceSituationIcon :data="exchange.shift"></ServiceSituationIcon>
      </div>
      <div class="card-content rounded-b-lg bg-card-content px-2 py-2">


        <div v-if="!offerView">

          <div v-if="exchange.checkout">
            <div class="alertbox border-green-600" v-if="exchange.checkout.status == 1">
              <div class="alertbox-icon bg-green-600">
                <font-awesome-icon icon="info-circle" class="icon"/>
              </div>
              <div class="alertbox-content text-center">
                <p class="alertbox-heading text-green-500">
                  <b>Dienst getauscht</b>
                </p>
                <p class="alertbox-maincontent">
                  Mit <i>{{exchange.checkout.user.first_name}} {{exchange.checkout.user.last_name}}</i>
                  <!-- {{getFormatedDate(exchange.date_updated)}} - {{getFormatedTime(exchange.date_updated)}} Uhr -->
                </p>
                <p class="alertbox-hint">
                  Wir haben Deinen <a class="link">Dienstplan</a> automatisch aktualisiert.
                </p>
              </div>
            </div>

            <div class="alertbox border-yellow-600" v-else-if="exchange.checkout.status == 3">
              <div class="alertbox-icon bg-yellow-600">
                <font-awesome-icon icon="info-circle" class="icon"/>
              </div>
              <div class="alertbox-content">
                <p class="alertbox-heading text-yellow-500">
                  <b>Tausch bestätigen!</b>
                </p>
                <p class="alertbox-maincontent">
                  Kontaktiere die Fahrdienstleitung, um den Tausch zu bestätigen.
                </p>
                <p class="alertbox-hint">
                  <a class="link">Tausch-Details öffnen</a> für mehr Informationen.
                </p>
              </div>
            </div>

            <div class="alertbox border-color-brand" v-else-if="exchange.checkout.status == 4">
              <div class="alertbox-icon bg-brand">
                <font-awesome-icon icon="info-circle" class="icon"/>
              </div>
              <div class="alertbox-content text-center">
                <p class="alertbox-heading text-blue-500">
                  <b>Warten auf Rückmeldung</b>
                </p>
                <p class="alertbox-maincontent">
                  Der Tauschvorgang wird bearbeitet.
                </p>
                <p class="alertbox-hint">
                  Wir benachrichtigen Dich, sobald sich der Status ändert.
                </p>
              </div>
            </div>

          </div>
          

          <div class="alertbox border-color-brand" v-else-if="exchange.exchange_submissions && exchange.exchange_submissions.length > 0 && exchange.exchange_submissions[0].status != 1">
            <div class="alertbox-icon bg-brand">
              <font-awesome-icon icon="info-circle" class="icon"/>
            </div>
            <div class="alertbox-content text-center">
              <p class="alertbox-heading text-blue-500">
                <b>Anfragen beantworten</b>
              </p>
              <p class="alertbox-maincontent">
                Du hast {{exchange.exchange_submissions.length}} offene Anfragen.
              </p>
            </div>
          </div>

        </div>



        <div class="alertbox border-color-brand" v-if="exchange.status == 0">
          <div class="alertbox-icon bg-brand">
            <font-awesome-icon icon="info-circle" class="icon"/>
          </div>
          <div class="alertbox-content text-center">
            <p class="alertbox-heading text-blue-500">
              <b>Warten auf Rückmeldung</b>
            </p>
            <p class="alertbox-maincontent">
              Du hast Deinen Dienst zum Tausch angeboten.
            </p>
            <p class="alertbox-hint">
              Wir benachrichtigen Dich, sobald Deine Anfrage beantwortet wurde.
            </p>
          </div>
        </div>

        <div class="alertbox border-green-600" v-else-if="exchange.status == 1">
          <div class="alertbox-icon bg-green-600">
            <font-awesome-icon icon="info-circle" class="icon"/>
          </div>
          <div class="alertbox-content text-center">
            <p class="alertbox-heading text-green-500">
              <b>Dienst getauscht</b>
            </p>
            <p class="alertbox-maincontent">
              Mit <i>{{exchange.exchange.user.first_name}} {{exchange.exchange.user.last_name}}</i><br />
              {{getFormatedDate(exchange.date_updated)}} - {{getFormatedTime(exchange.date_updated)}} Uhr
            </p>
            <p class="alertbox-hint">
              Wir haben Deinen <a class="link">Dienstplan</a> automatisch aktualisiert.
            </p>
          </div>
        </div>

        <div class="alertbox border-red-500" v-else-if="exchange.status == 2">
          <div class="alertbox-icon bg-red-500">
            <font-awesome-icon icon="info-circle" class="icon"/>
          </div>
          <div class="alertbox-content">
            <p class="alertbox-heading text-red-400">
              <b>Angebote abgelehnt</b>
            </p>
            <p class="alertbox-maincontent">
              Dein Tauschangebot wurde abgelehnt.
            </p>
            <p class="alertbox-hint">
              Dein Angebot wurde abgelehnt, weil die Anfrage abgelaufen, zurückgezogen oder abgelehnt wurde.
            </p>
          </div>
        </div>

        <div class="alertbox border-color-brand" v-else-if="exchange.status == 3">
          <div class="alertbox-icon bg-brand">
            <font-awesome-icon icon="info-circle" class="icon"/>
          </div>
          <div class="alertbox-content text-center">
            <p class="alertbox-heading text-blue-500">
              <b>Warten auf Rückmeldung</b>
            </p>
            <p class="alertbox-maincontent">
              Der Tauschvorgang wird bearbeitet.
            </p>
            <p class="alertbox-hint">
              Wir benachrichtigen Dich, sobald sich der Status ändert.
            </p>
          </div>
        </div>

        <div class="alertbox border-red-500" v-else-if="exchange.status == 11">
          <div class="alertbox-icon bg-red-500">
            <font-awesome-icon icon="info-circle" class="icon"/>
          </div>
          <div class="alertbox-content">
            <p class="alertbox-heading text-red-400">
              <b>Tausch abgebrochen</b>
            </p>
            <p class="alertbox-maincontent">
              Der Tauschvorgang wurde aufgrund betriebstechnischen Gründen vorzeitig abgebrochen
            </p>
          </div>
        </div>

        <div class="alertbox border-red-500" v-else-if="exchange.status == 14">
          <div class="alertbox-icon bg-red-500">
            <font-awesome-icon icon="info-circle" class="icon"/>
          </div>
          <div class="alertbox-content">
            <p class="alertbox-heading text-red-400">
              <b>Angebot abgelehnt</b>
            </p>
            <p class="alertbox-maincontent">
              Dein Tauschangebot wurde abgelehnt.
            </p>
            <p class="alertbox-hint">
              Dein Angebot wurde abgelehnt, weil die Anfrage abgelaufen, zurückgezogen oder abgelehnt wurde.
            </p>
          </div>
        </div>

        <div class="alertbox border-red-500" v-else-if="exchange.status == 15">
          <div class="alertbox-icon bg-red-500">
            <font-awesome-icon icon="info-circle" class="icon"/>
          </div>
          <div class="alertbox-content">
            <p class="alertbox-heading text-red-400">
              <b>Angebot abgelehnt</b>
            </p>
            <p class="alertbox-maincontent">
              Dein Tauschangebot wurde abgelehnt.
            </p>
            <p class="alertbox-hint">
              Dein Angebot wurde abgelehnt, weil die Anfrage abgelaufen, zurückgezogen oder abgelehnt wurde.
            </p>
          </div>
        </div>

        

        <!-- <p v-if="!minimalView && canRemove">Status: {{getExchangeStatus(exchange.status)}} ({{exchange.status}})</p> -->

        <!-- <div class="alertbox border-green-600">
          <div class="alertbox-icon bg-green-600">
            <font-awesome-icon icon="check" class="icon"/>
          </div>
          <div class="alertbox-content">
            <p class="alertbox-heading text-green-500">
              <b>Dienst getauscht</b>
            </p>
            <p class="alertbox-maincontent">
              Marcel Bels<br />
              01.01.2021 - 00:00 Uhr
            </p>
            <p class="alertbox-hint">
              Wir haben Deinen <a class="link">Dienstplan</a> automatisch
              aktualisiert.
            </p>
          </div>
        </div> -->

        <!-- <div class="alertbox border-yellow-600">
          <div class="alertbox-icon bg-yellow-600">
            <font-awesome-icon icon="info-circle" class="icon"/>
          </div>
          <div class="alertbox-content">
            <p class="alertbox-heading text-yellow-500">
              <b>Angebote prüfen!</b>
            </p>
            <p class="alertbox-maincontent">
              Du hast 3 offene Angebote.
            </p>
            <p class="alertbox-hint">
              <a class="link">Tausch-Details öffnen</a> für mehr Informationen.
            </p>
          </div>
        </div> -->

        <!-- <div class="alertbox border-yellow-600">
          <div class="alertbox-icon bg-yellow-600">
            <font-awesome-icon icon="info-circle" class="icon"/>
          </div>
          <div class="alertbox-content">
            <p class="alertbox-heading text-yellow-500">
              <b>Tausch bestätigen!</b>
            </p>
            <p class="alertbox-maincontent">
              Kontaktiere den Fahrdienstleiter.
            </p>
            <p class="alertbox-hint">
              <a class="link">Tausch-Details öffnen</a> für mehr Informationen.
            </p>
          </div>
        </div> -->

        <!-- <div class="alertbox border-red-500">
          <div class="alertbox-icon bg-red-500">
            <font-awesome-icon icon="info-circle" class="icon"/>
          </div>
          <div class="alertbox-content">
            <p class="alertbox-heading text-red-400">
              <b>Angebote abgelaufen</b>
            </p>
            <p class="alertbox-maincontent">
              Der Tausch wurde nicht bis zum Dienstbeginn bestätigt und wird nicht mehr öffentlicht angezeigt.
            </p>
          </div>
        </div> -->

        <div v-if="!offerView">
          <p class="mt-2 font-semibold underline">Angebot</p>

          <ShiftDetails :data="exchange.shift" class="text-center"></ShiftDetails>

          <hr />

          <p class="mt-2 font-semibold underline">Gewünscht</p>
          <div class="card-content-badges">
            <div class=" flex flex-wrap gap-2 items-center my-2">

              <div v-for="wishshift_time in exchange.wishshift_time" :key="wishshift_time.duty_times_id.id">
                <div class="tooltip"><ServiceSituationIcon :icon="wishshift_time.duty_times_id.typ" class="text-2xl"></ServiceSituationIcon>
                <span class="tooltiptext">{{wishshift_time.duty_times_id.name}}</span></div>
              </div>

              <div v-for="wishshift_specialrequirements in exchange.wishshift_specialrequirements" :key="wishshift_specialrequirements.special_requirements_id.id" class="badge badge-white">
                {{wishshift_specialrequirements.special_requirements_id.name}}
              </div>

              <div v-for="wishshift_lines in exchange.wishshift_lines" :key="wishshift_lines.lines_id.id" class="badge badge-white">
                Line {{wishshift_lines.lines_id.name}}
              </div>
            </div>
            <div class="card-content-badges flex flex-wrap gap-2 items-center my-2">
              <div v-for="wishshift_shiftstart in exchange.wishshift_shiftstart" :key="wishshift_shiftstart.stations_id.id" class="badge badge-white tooltip">
                <font-awesome-icon icon="caret-right" class=""/> {{wishshift_shiftstart.stations_id.shortname}}
                <span class="tooltiptext">{{wishshift_shiftstart.stations_id.name}}</span>
              </div>
            </div>
            <div class="card-content-badges flex flex-wrap gap-2 items-center my-2">
              <div v-for="wishshift_shiftend in exchange.wishshift_shiftend" :key="wishshift_shiftend.stations_id.id" class="badge badge-white tooltip">
                <font-awesome-icon icon="map-marker" class=""/> {{wishshift_shiftend.stations_id.shortname}}
                <span class="tooltiptext">{{wishshift_shiftend.stations_id.name}}</span>
              </div>
            </div>
              
          </div>
          <div class="commentar" v-if="exchange.commentar !== null">
            <!-- "Suche einen Mitteldienst mit Einfahrt Btf. Merheim mit
            vergleichbarer Endzeit." -->
            <p class="font-semibold">Kommentar:</p>
            <p>{{exchange.commentar}}</p>
          </div>

          <div v-if="!minimalView && canRemove">
            <hr />

            <p v-if="!minimalView && canRemove">Anfragen gesamt: {{exchange.exchange_submissions.length}}</p>
            <ul class="list-decimal list-inside">
              <li v-for="submission in exchange.exchange_submissions" :key="submission.id">Status: {{getExchangeStatus(submission.status)}} ({{submission.status}})</li>
            </ul>
             <hr />
          </div>

          <div class="my-3 flex gap-2 flex-wrap" v-if="!minimalView">
            <a class="card-button w-full text-center font-semibold" :href="'dienst/'+exchange.id"><font-awesome-icon icon="eye" class="icon"/> Ansehen</a> 
            <!-- <a class="card-button" v-if="canRemove" @click="removeExchange()"><font-awesome-icon icon="trash" class="icon color-danger"/></a> -->
          </div>
        </div>
        <div v-else>
          <p class="mt-2 font-semibold underline">Dein Dienst</p>
          <ShiftDetails :data="exchange.shift" class="text-center"></ShiftDetails>
          <hr />
          <p class="mt-2 font-semibold underline">Angebot</p>
          <ShiftDetails :data="exchange.exchange.shift" class="text-center"></ShiftDetails>
          <div class="my-3 flex gap-2 flex-wrap">
            <!-- <p class="uppercase text-center">{{getExchangeStatus(exchange.status)}}<br><br></p> -->
            <a class="card-button w-full text-center font-semibold" :href="'dienst/'+exchange.exchange.id"><font-awesome-icon icon="eye" class="icon"/> Ansehen</a> 
            
            <!-- <a class="card-button" @click="removeExchange()"><font-awesome-icon icon="trash" class="icon color-danger"/></a> -->
          
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import ServiceSituationIcon from "@/components/ServiceSituationIcon";
import ShiftDetails from "@/components/ShiftDetail";

export default {
  name: "ExchangeItem",
  components: {
    ServiceSituationIcon,
    ShiftDetails
  },
  props: {
    exchange: null,
    minimalView: false,
    canRemove: false,
    offerView: false
  },
  methods: {
    // selectItem() {
    //   this.$emit('selectItem', this.index)
    // }
    removeExchange() {
      alert('Computer sagt nein');
    }
  },
  computed: {
    ...mapGetters({
      getWeekdayForDate: "getWeekdayForDate",
      getFormatedDate: "getFormatedDate",
      getFormatedTime: "getFormatedTime",
      getServiceSituationIcon: "getServiceSituationIcon",
      getExchangeStatus: "getExchangeStatus"
    }),
  }
};
</script>
