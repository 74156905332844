<template>
  <!-- <div class="flex flex-wrap items-center justify-center gap-x-8 gap-y-6"> -->
  <div class="">
    <div v-for="shift in data.shift" :key="shift.id" class="py-3">
      <div v-if="shift.typ == 4 || shift.typ == 0">
        <!-- <p class="text-lg"  v-if="
          (new Date(data.date).getDay() == 0 && shift.line == 3 && shift.cycle == 72) ||
          (new Date(data.date).getDay() == 6 && shift.line == 3 && shift.cycle == 16) ||
          ((new Date(data.date).getDay() > 0 && new Date(data.date).getDay() < 6) && shift.line == 3 && shift.cycle == 18)
          ">
          <font-awesome-icon icon="subway" class="icon animate-ping" /> <b class="animate-pulse"> Umlaufstück</b> 
        </p> -->
        <p class="text-lg">
          <font-awesome-icon icon="subway" class="icon" /> <b> Umlaufstück</b> <!--<span v-show="shift.line == '1'" class="w-2 h-2 rounded-full bg-red-800 absolute"></span><span v-show="shift.line == '1'" class="animate-ping w-3 h-3 rounded-full bg-red-800 absolute"></span>-->

        </p>

        <p class="py-1">
          <b class="brand-color badge">{{ shift.line }}/{{ shift.cycle }}</b>
        </p>
        <!-- <div class="grid grid-cols-2 gap-x-2">
          <p class="place-self-end">
          {{ shift.start_time }} Uhr
        </p>
        <p class="place-self-start">
          {{ shift.start_location }} →
          {{ shift.start_direction }}
        </p>
        </div> -->
        <p>
          {{ shift.start_time }} Uhr - {{ shift.start_location }} &rarr; {{ shift.start_direction }}
        </p>
        <p>
          {{ shift.end_time }} Uhr - {{ shift.end_location
          }}<span v-if="shift.end_direction">
            &rarr; {{ shift.end_direction }}</span
          >
        </p>
          
      </div>

      <div v-if="shift.typ == 1">
        <p class="text-lg">
          <font-awesome-icon icon="mug-hot" class="icon" />
          <b> Unbezahlte Pause</b>
        </p>
        <p>{{ shift.start_location }}</p>
        <p>{{ shift.start_time }} Uhr bis {{ shift.end_time }} Uhr</p>
      </div>

      <div v-if="shift.typ == 2">
        <p class="text-lg">
          <font-awesome-icon icon="angle-double-right" class="icon" />
          <b> Mitfahrt</b>
        </p>
        <p>{{ shift.start_time }} Uhr - {{ shift.start_location }}</p>
        <p>{{ shift.end_time }} Uhr - {{ shift.end_location }}</p>
      </div>

      <div v-if="shift.typ == 3">
        <p class="text-lg">
          <font-awesome-icon icon="phone-volume" class="icon" />
          <b> Reserve</b>
        </p>
        <p>{{ shift.start_time }} Uhr bis {{ shift.end_time }} Uhr</p>
        <p>{{ shift.start_location }}</p>
      </div>

      <div v-if="shift.typ == 5">
        <p class="text-lg">
          <font-awesome-icon icon="hourglass-end" class="icon" />
          <b> Überstundenabbau</b>
        </p>
        <p>{{ shift.start_time }} Uhr bis {{ shift.end_time }} Uhr</p>
      </div>

      <div v-if="shift.typ == 6">
        <p class="text-lg">
          <font-awesome-icon icon="hourglass-start" class="icon" />
          <b> Überstundenaufbau (Überstundenkonto)</b>
        </p>
        <p>{{ shift.start_time }} Uhr bis {{ shift.end_time }} Uhr</p>
      </div>

      <div v-if="shift.typ == 7">
        <p class="text-lg">
          <font-awesome-icon icon="hourglass-start" class="icon" />
          <b> Überstundenaufbau (Bezahlt)</b>
        </p>
        <p>{{ shift.start_time }} Uhr bis {{ shift.end_time }} Uhr</p>
      </div>

      <div v-if="shift.typ == 8">
        <p class="text-lg">
          <font-awesome-icon icon="hourglass-start" class="icon" />
          <b> Verspäteter Dienstantritt</b>
        </p>
        <p>{{ shift.start_time }} Uhr bis {{ shift.end_time }} Uhr</p>
      </div>

      <div v-if="shift.typ == 9">
        <p class="text-lg">
          <font-awesome-icon icon="random" class="icon" />
          <b> Rangierdienst</b>
        </p>
        <p>{{ shift.start_location }}</p>
        <p>{{ shift.start_time }} Uhr bis {{ shift.end_time }} Uhr</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ShiftDetails",
  data() {
    return {};
  },
  props: {
    data: null,
  },
  methods: {},
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([]),
  },
};
</script>