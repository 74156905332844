<template>
  <div id="B">
    <h1 class="brand-color-dark">Angebotene Dienste</h1>
    <h4>Deine Dienste, die Du zum Tausch auf Tauschangebote eingereicht hast.</h4>

    <NoDataBox v-if="exchangesWithUser.length == 0" headline="Keine Tauschangebote">Du bietest zurzeit niemanden Dienste an.<br />
        Schau Dir die <router-link class='link' to="verfuegbare-dienste">verfügbaren Tauschangebote</router-link> an, um einen Dienst zum Tausch
        auszuwählen.</NoDataBox>

        <!-- <div class="content-card-container">
          
        <div class="content-card" v-for="exchange in exchanges">
          <div class="card-header card-header-flex">
            <p>{{ getWeekdayForDate(exchange.shift.header_date, false) }}, den {{ getFormatedDate(exchange.shift.header_date) }}</p>
            <ServiceSituationIcon :data="exchange.shift"></ServiceSituationIcon>
          </div>
          <div class="card-content">
            <ShiftDetails :data="exchange.shift"></ShiftDetails>

            <hr />

            <div class="card-content-badges">
              <ServiceSituationIcon :icon="exchange.wishshift_time"></ServiceSituationIcon>
              <div v-if="exchange.wishshift_ebo" class="badge badge-white">EBO</div>
              <div class="badge badge-white">
                <font-awesome-icon icon="map-marker" class=""/> MRH-S
              </div>
              <div class="badge badge-white">
                <font-awesome-icon icon="caret-right" class=""/> NEU
              </div>
              
            </div>
            <p class="commentar" v-if="exchange.commentar !== null">
              {{exchange.commentar}}
            </p>
          </div>
          <div class="card-footer">
            <a class="card-button"><font-awesome-icon icon="eye" class="icon"/></a>
          </div>
        </div>
      </div> -->

      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
        <ExchangeItem  v-for="exchange in exchangesWithUser" :key="exchange.id" :exchange="exchange" :canRemove="true" offerView="true"></ExchangeItem>
      </div>

    <div class="text-center" style="margin-top:50px"> 
        <small >Insgesamt {{exchangesWithUser && exchangesWithUser.length ? exchangesWithUser.length : 0}} Dienste</small>
      </div>

  </div>
</template>


<script>
import { mapGetters } from "vuex";

import NoDataBox from "@/components/NoDataBox";
import ServiceSituationIcon from "@/components/ServiceSituationIcon";
import ShiftDetails from "@/components/ShiftDetail";
import ExchangeItem from "@/components/ExchangeItem";

export default {
  name: "B",
  components: {
    NoDataBox,
    ServiceSituationIcon,
    ShiftDetails,
    ExchangeItem
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {
    this.$store.dispatch("fetchExchangesWithUser");
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      exchangesWithUser: "getExchangesWithUser",
      getWeekdayForDate: "getWeekdayForDate",
      getFormatedDate: "getFormatedDate",
      getServiceSituationIcon: "getServiceSituationIcon",
    }),
  },
};
</script>